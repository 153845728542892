import { useStorage } from '@vueuse/core'
import type { ShopRentalTimeFrame } from '../types'

// Local storage
const deliveryLocation = useStorage<string>('shop:delivery-location', '')

interface LocalStorageCoords {
  lat: string | undefined
  lng: string | undefined
}
const deliveryLocationCoords = useStorage<LocalStorageCoords>('shop:delivery-location-coords', { lat: undefined, lng: undefined })
const deliveryCoords = useStorage<LocalStorageCoords>('shop:delivery-coords', { lat: undefined, lng: undefined })

const isSelfCollection = useStorage<boolean>('shop:is-self-collection', false)
const rentalStart = useStorage<number | undefined>('shop:rental-start', undefined)
const rentalEnd = useStorage<number | undefined>('shop:rental-end', undefined)

const cartId = ref<string | null>(null)

const isCartDrawerOpen = ref<boolean>(false)

export default () => {
  const rentalTimeFrame = computed<ShopRentalTimeFrame>(() => {
    if (!rentalStart.value || !rentalEnd.value) {
      return undefined
    }
    return [Number(rentalStart.value), Number(rentalEnd.value)]
  })

  function updateRentalTimeFrame(timeframe: ShopRentalTimeFrame) {
    if (!timeframe) {
      rentalStart.value = undefined
      rentalEnd.value = undefined
      return
    }
    rentalStart.value = timeframe[0]
    rentalEnd.value = timeframe[1]
  }

  // Shopping cart
  const cartIdCookie = useCookie<string | null>('shop:cartId')
  if (cartIdCookie.value) {
    cartId.value = cartIdCookie.value
  }

  const { $trpc } = useNuxtApp()
  async function clearCart() {
    if (!cartId.value) {
      return
    }

    await $trpc.shop.cart.clearShopCart.mutate({ id: cartId.value })
    updateCartId(null)
  }

  function updateCartId(id: string | null) {
    cartId.value = id
    cartIdCookie.value = id
  }

  return {
    rentalTimeFrame,
    updateRentalTimeFrame,
    deliveryLocation,
    deliveryLocationCoords,
    deliveryCoords,
    isSelfCollection,
    cartId: readonly(cartId),
    isCartDrawerOpen,
    updateCartId,
    clearCart,
    rentalTime: readonly({
      startDate: rentalStart,
      endDate: rentalEnd,
    }),
  }
}
