import { useInfiniteQuery, useQuery } from '@tanstack/vue-query'
import type { ShopRentalTimeFrame } from '../types'
import type { ShopGroupAggregateMachinery } from '~/layers/customer-module/types'
import type { ShopMachineryFiltersInput, SimilarMachineryGroupsFilter } from '~/server/trpc/routers/shop/machinery'
import type { ShopMachineryAccessoryFiltersInput } from '~/server/trpc/routers/shop/machineryAccessory'
import type { MachineryCategories, Pagination, ShopMachineryByFiltersInput } from '~/types'

const KEY = 'shop'
export const SHOP_QUERY_KEY = KEY

type _Where = Record<string, unknown>
type Where = Ref<_Where>

type RentalTimeFrame = Ref<ShopRentalTimeFrame>

export default () => {
  const { $trpc } = useNuxtApp()
  const { updateCartId } = useShopCart()
  const isAuthenticated = computed(() => useAuth().status.value === 'authenticated')

  return {
    machineryQuery: {
      getAllReleasedForRent: (cartId: MaybeRef<string | null>, rentalTimeFrame: RentalTimeFrame, filters?: MaybeRef<ShopMachineryFiltersInput>, where?: Where, userInput?: MaybeRef<string | undefined>, pageSize = 12) => useInfiniteQuery({
        queryFn: ({ pageParam }) => {
          return $trpc.shop.machinery.getAllReleasedForRent.query({
            filters: unref(filters),
            where: unref(where),
            userInput: unref(userInput),
            showFavorites: !!unref(filters)?.showFavorites,
            cartId: unref(cartId),
            rentalTimeFrame: rentalTimeFrame.value,
            pagination: { skip: pageParam, take: pageSize },
          })
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, lastPageParam) => {
          if (lastPage.machineryGroups.length === 0) {
            return undefined
          }
          return lastPageParam + 12
        },
        queryKey: [KEY, 'machinery', filters, where, userInput, cartId, rentalTimeFrame],
      }),
      getByMachineryCategoryFilter: (filters: MaybeRef<ShopMachineryByFiltersInput>, cartId: Ref<string | null>, rentalTimeFrame: RentalTimeFrame) => useQuery({
        queryFn: () => {
          return $trpc.shop.machinery.getByMachineryCategoryFilter.query({
            filters: unref(filters),
            cartId: cartId.value,
            rentalTimeFrame: rentalTimeFrame.value,
          })
        },
        queryKey: [KEY, 'machinery', 'getByMachineryCategoryFilter', filters, cartId, rentalTimeFrame],
      }),
      getAllTypes: (where?: MaybeRef<{ category?: MachineryCategories }>) => useQuery({
        queryFn: () => $trpc.shop.machinery.getAllTypes.query(unref(where)),
        queryKey: [KEY, 'machineryType', where],
      }),
      getAllTypesAndCategories: (where: Ref<string>) => useQuery({
        queryFn: () => $trpc.shop.machinery.getAllTypesAndCategories.query(unref(where)),
        queryKey: [KEY, 'machineryType', where],
      }),
      getAllDrives: () => useQuery({
        queryFn: () => $trpc.shop.machinery.getAllDrives.query(),
        queryKey: [KEY, 'machineryDrive'],
      }),
      getReleasedForRentByAggregation: (payload: ShopGroupAggregateMachinery) => useQuery({
        queryFn: () => $trpc.shop.machinery.getReleasedForRentByAggregation.query({ payload }),
        queryKey: [KEY, 'machinery', 'getReleasedForRentByAggregation', payload],
      }),
      getSimilarMachineryGroups: (filters: Ref<SimilarMachineryGroupsFilter>, cartId: Ref<string | null>, rentalTimeFrame: RentalTimeFrame) => useQuery({
        queryFn: () => {
          return $trpc.shop.machinery.getSimilarMachineryGroups.query({
            filters: unref(filters),
            cartId: cartId.value,
            rentalTimeFrame: rentalTimeFrame.value,
          })
        },
        queryKey: [KEY, 'machinery', 'getByMachineryCategoryFilter', filters, cartId, rentalTimeFrame],
      }),
    },
    cartQuery: {
      getByIdOrCreate: (id: MaybeRef<string | null>, machineryPriceCalculationProps: { rentalTimeFrame: RentalTimeFrame } | null = null, enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: async () => {
          const cart = await $trpc.shop.cart.getByIdOrCreate.query({
            id: unref(id),
            machineryPriceCalculationProps: machineryPriceCalculationProps
              ? { rentalTimeFrame: machineryPriceCalculationProps.rentalTimeFrame.value }
              : null,
          })

          updateCartId(cart.id)
          return cart
        },
        enabled: unref(enabled),
        queryKey: [KEY, 'cart', 'byIdOrCreate', id, machineryPriceCalculationProps, enabled],
      }),
      getById: (id: MaybeRef<string>, machineryPriceCalculationProps: { rentalTimeFrame: RentalTimeFrame }) => useQuery({
        queryFn: () => {
          return $trpc.shop.cart.getById.query({
            id: unref(id),
            machineryPriceCalculationProps: {
              rentalTimeFrame: machineryPriceCalculationProps.rentalTimeFrame.value,
            },
          })
        },
        queryKey: [KEY, 'cart', 'byId', id, machineryPriceCalculationProps],
      }),
      getUserDataForCart: () => useQuery({
        queryFn: () => $trpc.shop.cart.getUserDataForCart.query(),
        queryKey: ['customerModule', 'account', 'self'],
      }),
    },
    machineryAccessoryQuery: {
      getAllReleasedForSale: (filters?: MaybeRef<ShopMachineryAccessoryFiltersInput>, where?: Where, pagination?: MaybeRef<Pagination>) => useQuery({
        queryFn: () => $trpc.shop.machineryAccessory.getAllReleasedForSale.query({
          filters: unref(filters),
          where: unref(where),
          pagination: unref(pagination),
        }),
        queryKey: [KEY, 'machineryAccessory', filters, where, pagination],
      }),
    },
    favoriteMachineryGroupsQuery: {
      getAllByUser: () => useQuery({
        queryFn: () => $trpc.shop.favoriteMachineryGroup.getAllByUser.query(),
        queryKey: [KEY, 'favoriteMachineryGroup'],
        enabled: isAuthenticated,
      }),
    },
  }
}
